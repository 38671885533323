<template>
  <transition class="la-form-document-field" name="fade">
    <div class="la-form-document-field">
      <div class="field-container p-2">
        <fd-input
          :value="title"
          class="col-12 px-1 mb-2"
          label="Title"
          name="LAdocumentFieldTitle"
          type="text"
          :validators="[validator.required]"
          @input="
            (val) => {
              this.$emit('update:title', val);
            }
          "
        >
        </fd-input>
        <fd-textarea
          :value="subTitle"
          class="col-12 px-1 mb-2"
          label="Subtitle"
          name="LAdocumentFieldsubTitle"
          @input="
            (val) => {
              this.$emit('update:subTitle', val);
            }
          "
        >
        </fd-textarea>
        <fd-checkbox
          :value="isRequired"
          class="col-12 px-1 mb-2"
          label="Required"
          @input="(val) => $emit('update:isRequired', val)"
        >
        </fd-checkbox>
        <div class="row justify-content-end px-1">
          <button
            type="button"
            class="btn danger bordered"
            @click="$emit('remove')"
          >
            <i class="fas fa-trash mr-1"></i>Remove
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  components: {},
  mixins: [],
  props: {
    title: {
      type: String,
      default: ""
    },
    subTitle: {
      type: String,
      default: ""
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      validator: {
        required: required
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.la-form-document-field {
  transition: 0.2s;
  .field-container {
    border: solid 1px #ccc;
    border-radius: 5px;
  }
}
</style>
